

















































import Vue from "vue";
import Loader from "@/components/layout/Loader.vue";
import WorkspaceLogo from "@/components/layout/WorkspaceLogo.vue";

import { OrganizationSummary } from "@/models/organization";
import { ActionTypes } from "@/store/action-types";
import { RouteName } from "@/router/index.ts";
import { handleKeyNavigation } from "@/utils";

export default Vue.extend({
  components: { Loader, WorkspaceLogo },
  data() {
    return {
      filter: "",
      dashboardRoute: RouteName.DASHBOARD
    };
  },
  created() {
    this.$storeTyped.dispatch(ActionTypes.FETCH_ORGANIZATION_SUMMARIES);
  },
  computed: {
    organizations(): OrganizationSummary[] | null {
      return this.$storeTyped.state?.organization?.organizationSummaries;
    },
    filteredOrganizations(): OrganizationSummary[] | null {
      return this.organizations
        ? this.organizations.filter(o =>
            o.name
              .toLowerCase()
              .trim()
              .includes(this.filter.toLowerCase().trim())
          )
        : null;
    },
    canRedirect() {
      return this.$route.params.noRedirect !== "true";
    }
  },
  watch: {
    organizations(orgs) {
      if (orgs && orgs.length === 1 && this.canRedirect) {
        this.$router.push({
          name: RouteName.DASHBOARD,
          params: { orgId: orgs[0].id }
        });
      }
    }
  },
  methods: {
    logout() {
      this.$storeTyped.dispatch(ActionTypes.LOGOUT_USER);
    },
    handleFocus(e: KeyboardEvent) {
      const onSuccess = (id: string) => {
        this.$router.push({
          name: RouteName.DASHBOARD,
          params: { orgId: id }
        });
      };

      handleKeyNavigation(
        ".organization-list",
        "#organization-filter",
        "selected",
        e,
        onSuccess
      );
    }
  }
});
